import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Table} from "rsuite";
import {getAdminDataAPi} from "../../servies/admin.services";
import ToasterMessage from "../common/toaster_message";
import {getAuthSession, removeAuthSession} from "../../servies/common.services";
import {predictionsMetricsApi} from "../../servies/facebook.services";
import {getSERVER_URL} from "../../app.config";


const { Column, HeaderCell, Cell } = Table;

export const VideoFeedback = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [authData, setAuthData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [tableData, setTableData] = useState([]);
    const [serverURL, setServerURL] = useState('');

    const predictionsMetricsAPI = async (params) => {
        setIsLoading(true);
        predictionsMetricsApi(params)
            .then((res) => {
                console.log(res);

                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                setTableData(data);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsLoading(false);


            })
            .finally(() => {
                console.log('finally');
                setIsLoading(false);
            })
    };

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);
        console.log('authData', authData)

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        predictionsMetricsAPI(params);
    },[]);




    return (
        <Table data={tableData} autoHeight loading={isLoading}>
            {/* Month Column */}
            <Column width={150} align="center">
                <HeaderCell>Month</HeaderCell>
                <Cell dataKey="month" />
            </Column>

            {/* % Thumbs Up Column */}
            <Column width={150} align="center">
                <HeaderCell>% Thumbs Up</HeaderCell>
                <Cell>
                    {rowData => {
                        return <a target={'_blank'} href={rowData.thumbs_up_link}>{rowData.thumbs_up}%</a>;
                    }}
                </Cell>
            </Column>

            {/* % Thumbs Down Column */}
            <Column width={150} align="center">
                <HeaderCell>% Thumbs Down</HeaderCell>
                <Cell>
                    {rowData => {
                        // Build link for predictions filtered for thumbs down feedback during the given date range.
                        return <a target={'_blank'} href={rowData.thumbs_down_link}>{rowData.thumbs_down}%</a>;
                    }}
                </Cell>
            </Column>
        </Table>
    )




}