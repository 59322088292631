import './App.css';
import Error404 from './pages/errors/error404';
import ExitIframe from "./pages/ExitIframe";
import React, {useCallback, useState} from "react";
import {CustomProvider, Container} from 'rsuite';
import Dashboard from "./pages/Dashboard";
import Error400 from "./pages/errors/error400";
import "@shopify/polaris/build/esm/styles.css";
import {ClientRouter, RoutePropagator} from "@shopify/app-bridge-react";
import {AppContext} from "./lib/contextLib";
import {BrowserRouter, Routes, Route, useNavigate, useRoutes, useLocation} from "react-router-dom";
import {AppProvider} from "@shopify/polaris";
import createApp from '@shopify/app-bridge';
import {FacebookProvider} from "react-facebook";
import './static/embla.css'

import 'rsuite/dist/rsuite.min.css';
import {AdminSideBar} from "./components/admin/AdminSideBar";
import {AdminDashboard} from "./components/admin/AdminDashboard";
import {AdminPanel} from "./pages/admin/AdminPanel";
import {ViewPaymentPlan} from "./components/admin/PaymentPlan/ViewPaymentPlan";
import {AdminAuth} from "./pages/admin/AdminAuth";
import {ViewSettings} from "./pages/admin/Settings/ViewSettings";
import {PredictionJob} from "./pages/admin/Predictions/PredictionJob";
import {GeneratedPredictions} from "./pages/admin/Predictions/GeneratedPredictions";
import { clarity } from 'react-microsoft-clarity';
import {VideoFeedback} from "./components/admin/VideoFeedback";

function RoutesApp() {
    let routes = useRoutes([
        {path: '/', element: <Dashboard/>},
        {path: '/admin', element: <AdminSideBar/>},
        {path: "/exit-iframe", element: <ExitIframe/>,},
        {path: "/not-auth", element: <Error400/>,},
        {path: '*', element: <Error404/>},
    ]);
    return routes;
}

function App() {

    const [error, setError] = useState('');
    const [isToastActive, setIsToastActive] = useState(false);
    const toggleToastActive = useCallback(() => setIsToastActive((active) => !active), []);
    const [shopData, setShopData] = useState({});
    const [shopState, setShopState] = useState({});
    const [facebookAccounts, setFacebookAccounts] = useState([]);
    const [selectedFacebookAccount, setSelectedFacebookAccount] = useState('');
    const [totalAIImagesCount, setTotalAIImagesCount] = useState(0);
    const [totalProductGenerated, setTotalProductGenerated,] = useState(0);
    const [totalProducts, setTotalProducts,] = useState(0);
    const [isRefresh, setIsRefresh] = useState(false);
    const [bannerMessage, setBannerMessage] = useState(null);
    const [openPlanModal, setOpenPlanModal] = useState(false);
    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;


    const providerValue = {
        error, setError,
        isToastActive, setIsToastActive,
        toggleToastActive,
        shopData, setShopData,
        shopState, setShopState,
        facebookAccounts, setFacebookAccounts,
        selectedFacebookAccount, setSelectedFacebookAccount,
        totalAIImagesCount, setTotalAIImagesCount,
        totalProductGenerated, setTotalProductGenerated,
        totalProducts, setTotalProducts,
        isRefresh, setIsRefresh,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal

    };


    return (

        <AppContext.Provider value={providerValue}>
            <AppProvider>
                <FacebookProvider appId={facebookAppId}>
                    <CustomProvider>
                        <Container>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/admin">
                                        <Route index path="" element={<AdminPanel child={<AdminDashboard />}/>}/>
                                        <Route index path="plans" element={<AdminPanel child={<ViewPaymentPlan />}/>}/>
                                        <Route index path="video_feedback" element={<AdminPanel child={<VideoFeedback />}/>}/>
                                        <Route index path="settings" element={<AdminPanel child={<ViewSettings />}/>}/>
                                        <Route index path="jobs" element={<AdminPanel child={<PredictionJob />}/>}/>
                                        <Route index path="predictions" element={<AdminPanel child={<GeneratedPredictions />}/>}/>
                                        <Route path="login" element={<AdminAuth />}/>
                                    </Route>
                                    <Route path="/" element={<Dashboard/>}/>

                                    <Route path="/exit-iframe" element={<ExitIframe/>}/>
                                    <Route path="/not-auth" element={<Error400/>}/>
                                    <Route path="*" element={<Error404/>}/>
                                </Routes>
                            </BrowserRouter>
                        </Container>
                    </CustomProvider>
                </FacebookProvider>

            </AppProvider>
        </AppContext.Provider>
    );
}

export default App;
