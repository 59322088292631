import {axiosCall, getAPIChangePlanConfig, getAPIConfig} from "../app.config";
import axios from "axios";

export const getFacebookAccounts = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_FACEBOOK_ACCOUNTS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST');

    return  await axiosCall(axiosConfig);
}

export const facebookPublishImage = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_FACEBOOK_PUBLISH_IMAGE_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const predictionsMetricsApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_FEEDBACK_METRICS_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'GET');

    return  await axiosCall(axiosConfig);
}